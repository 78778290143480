@import 'scss/_variables';
@import 'scss/_mixins';

.header {
    @include blue_flash_kill;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0px;
    padding: 24px 24px 0px;
    width: 100vw;
    z-index: 2;

    > a {
        display: flex;
        align-items: center;
        height: 32px;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: -0.3px;
        color: #fff;
        text-decoration: none;
    }

    > div {
        @extend a;
        cursor: pointer;
    }

    @media screen and (min-width: #{$wideBreakPoint}px) {
        padding: 40px #{$logoPaddingLeftWide}px;
    }
}

.header_bg {
    @extend .header;
    background: transparent;
}
