@import 'scss/_variables';
@import 'scss/_mixins';

*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    scroll-behavior: smooth;
}

@font-face {
    font-family: 'Maax';
    font-display: auto;
    src: local('Maax'), url(./assets/fonts/Maax.otf);
    font-weight: 400;
}

@font-face {
    font-family: 'Maax';
    font-display: auto;
    src: local('Maax'), url(./assets/fonts/Maax-Medium.otf);
    font-weight: 500;
}

@font-face {
    font-family: 'Maax';
    font-display: auto;
    src: local('Maax'), url(./assets/fonts/Maax-Bold.otf);
    font-weight: 600;
}

@font-face {
    font-family: 'Maax';
    font-display: auto;
    src: local('Maax'), url(./assets/fonts/Maax-Black.otf);
    font-weight: 700;
}

body {
    margin: 0;
    padding: 0;
    color: $primary_text_color;
    background-color: $body-bg;
    font-size: $base_font_size;
    font-weight: $base_font_weight;
    font-family: 'Maax';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

p {
    font-family: 'Maax';
    font-weight: 400;
    font-size: 19px;
    line-height: 29px;
}

h1 {
    font-family: 'Maax';
    font-weight: 500;
    font-size: 54px;
    line-height: 65px;
}

h2 {
    font-family: 'Maax';
    font-weight: 500;
    font-size: 30px;
    line-height: 42px;
}

h3 {
    font-family: 'Maax';
    font-weight: 500;
    font-size: 28px;
    line-height: 42px;
}

h4 {
    font-family: 'Maax';
    font-weight: 500;
    font-size: 19px;
    line-height: 29px;
}

button::-moz-focus-inner {
    border: 0;
}

input::-moz-focus-inner {
    border: 0;
}

.spinner_center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loader:empty {
    width: 100vw;
    height: 100vh;
    background-image: url(./assets/images/ksc_splash_logo.png);
    background-position: center;
    background-repeat: no-repeat;
    background-color: #141414;
}
