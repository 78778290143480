@import './_variables';

// Kills touch event outline on elements
@mixin blue_flash_kill {
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

// Hide scroll (goes on parent)
@mixin hide_scroll($axis: both) {
    @if $axis == both {
        overflow: scroll;
    } @else {
        overflow-#{$axis}: scroll;
    }

    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none; /* Chrome, Safari */
    }
}

// Breakpoints
@mixin respond_to($breakpoint) {
    // If the key exists in the map
    @if map-has-key($breakpoints, $breakpoint) {
        // Prints a media query based on the value
        @media only screen and #{inspect(map-get($breakpoints, $breakpoint))} {
            @content;
        }
    }

    // If the key doesn't exist in the map
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
    }
}

// Basic transition for all props and 0.4s duration
@mixin basic_transition($property: all, $time_in_seconds: 0.4, $delay: 0) {
    transition: $property #{$time_in_seconds}s
        cubic-bezier(0.215, 0.61, 0.355, 1) #{$delay}s;
}

// Centre a block element
@mixin push_auto {
    margin: {
        left: auto;
        right: auto;
    }
}

@mixin square($size) {
    width: $size;
    height: $size;
}

@mixin pseudo($display: block, $pos: absolute, $content: '') {
    content: $content;
    display: $display;
    position: $pos;
}

// for creating scalable elements that maintain a ratio
@mixin responsive_ratio($x, $y, $pseudo: false) {
    $padding: unquote(($y / $x) * 100 + '%');
    @if $pseudo {
        &:before {
            @include pseudo($pos: relative);
            width: 100%;
            padding-top: $padding;
        }
    } @else {
        padding-top: $padding;
    }
}

// This mixin takes all the hassle out of creating that triangle you'll see coming out of most traditional tooltips
@mixin css_triangle(
    $color,
    $direction,
    $size: 6px,
    $position: absolute,
    $round: false
) {
    @include pseudo($pos: $position);
    width: 0;
    height: 0;
    @if $round {
        border-radius: 3px;
    }
    @if $direction == down {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-top: $size solid $color;
        margin-top: 0 - round($size / 2.5);
    } @else if $direction == up {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-bottom: $size solid $color;
        margin-bottom: 0 - round($size / 2.5);
    } @else if $direction == right {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-left: $size solid $color;
        margin-right: -$size;
    } @else if $direction == left {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-right: $size solid $color;
        margin-left: -$size;
    }
}

// Font settings
@mixin set_font(
    $size: false,
    $line_height: false,
    $weight: false,
    $spacing: false,
    $colour: false
) {
    @if $size {
        font-size: $size;
    }
    @if $colour {
        color: $colour;
    }
    @if $weight {
        font-weight: $weight;
    }
    @if $line_height {
        line-height: $line_height;
    }
    @if $spacing {
        letter-spacing: $spacing;
    }
}

// Retina
@mixin retina {
    @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
        only screen and (-moz-min-device-pixel-ratio: 1.5),
        only screen and (-o-min-device-pixel-ratio: 3 / 2),
        only screen and (min-device-pixel-ratio: 1.5),
        only screen and (min-resolution: 1.5dppx) {
        @content;
    }
}

// Circle Gradient
@mixin gradient($bottom, $top) {
    background-image: linear-gradient(
        to top right,
        $bottom,
        rgba(255, 255, 255, 0),
        $top
    );

    background-image: -moz-linear-gradient(
        to top right,
        $bottom,
        rgba(255, 255, 255, 0),
        $top
    );

    background-image: -webkit-linear-gradient(
        to top right,
        $bottom,
        rgba(255, 255, 255, 0),
        $top
    );

    background-image: -o-linear-gradient(
        to top right,
        $bottom,
        rgba(255, 255, 255, 0),
        $top
    );

    background-image: -ms-linear-gradient(
        to top right,
        $bottom,
        rgba(255, 255, 255, 0),
        $top
    );

    // background-size: cover;
    // background-position: center;
}
