.layout {
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    max-width: 100vw;
    height: 100%;
}

.bg {
    @extend .layout;
    background-attachment: fixed;
    background-image: url(~assets/images/onboarding_bg.svg);
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;

    @media screen and (min-width: 770px) {
        background-image: url(~assets/images/onboarding_bg_d.svg);
    }
}

.main {
    width: 100%;
    min-height: 100vh;
    position: relative;

    @media screen and (min-width: 770px) {
        display: flex;
        justify-content: center;
    }
}
